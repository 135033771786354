// servicesData.js

import ComputerIcon from "@mui/icons-material/Computer";
import PsychologyIcon from "@mui/icons-material/Psychology";
import PeopleIcon from "@mui/icons-material/People";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  PrideFlagIcon,
  TransgenderFlagIcon,
  AutismInfinityIcon,
} from "../../common/RainbowIcon";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";

export const categories = {
  Individual: [
    {
      label: "Female Sexual Dysfunction",
      description:
        "Provides targeted support and treatment options for women experiencing difficulties with sexual function, helping to enhance sexual health and well-being.",
      Icon: WomanIcon,
    },
    {
      label: "Male Sexual Dysfunction",
      description:
        "Offers comprehensive care for men facing sexual performance issues, aiming to improve sexual health through therapeutic interventions.",
      Icon: ManIcon,
    },
    {
      label: "Online Sexual Addiction",
      description:
        "Addresses compulsive behaviors and challenges associated with online sexual activities, helping individuals regain control and improve their relational health.",
      Icon: ComputerIcon,
    },
    {
      label: "Autism Support for Adults",
      description:
        "Specialized support addressing the unique life and social challenges faced by autistic adults, including workplace success, social relationships, and personal development.",
      Icon: AllInclusiveIcon,
    },
    {
      label: "Autism Support for Children",
      description:
        "Helps children with autism develop essential social skills, manage sensory sensitivities, and improve communication abilities, all within a nurturing environment.",
      Icon: AutismInfinityIcon,
    },
    {
      label: "LGBTQ Counseling",
      description:
        "Provides a safe space for exploring gender and sexual identity issues, offering support for related emotional and social challenges.",
      Icon: PrideFlagIcon,
    },
    {
      label: "Transgender Counseling",
      description:
        "Provides support for individuals exploring or undergoing gender transition, including emotional support, navigating social changes, and accessing medical resources. Focuses on building a positive sense of self and fostering resilience through the transition process.",
      Icon: TransgenderFlagIcon,
    },
    {
      label: "Straight Spouse Support",
      description:
        "Offers guidance and support for spouses of LGBTQ individuals, focusing on navigating changes in relationships and understanding gender and sexual identity shifts.",
      Icon: PsychologyIcon,
    },
  ],
  Relationship: [
    {
      label: "Marriage Counseling",
      description:
        "Facilitates stronger relationships through improved communication, conflict resolution, and enhanced intimacy and understanding between partners.",
      Icon: PeopleIcon,
    },
    {
      label: "Sex Therapy for Couples",
      description:
        "Specifically addresses sexual issues within relationships, promoting healthier sexual communication, understanding, and satisfaction.",
      Icon: FavoriteIcon,
    },
    {
      label: "Lack of Desire",
      description:
        "Addresses low sexual desire in relationships, helping partners understand and navigate differences in libido and sexual needs.",
      Icon: FavoriteBorderIcon,
    },
  ],
};

export const services = Object.entries(categories).flatMap(
  ([category, services]) =>
    services.map((service) => ({
      ...service,
      category,
    }))
);
