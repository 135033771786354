import React from "react";
import { Box } from "@mui/material";

function GoogleMapsSection() {
  const googleMapsSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.7893978895963!2d-81.39789019999999!3d28.6360734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e770670026a785%3A0xbe00777d37a9ce98!2sDr.%20Anne%20Rothenberg!5e0!3m2!1sen!2sus!4v1719684770821!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade`;

  return (
    <Box
      sx={{
        width: "100%",
        height: "400px",
      }}
    >
      <Box
        component="iframe"
        title="Google Maps location of Dr. Anne Rothenberg"
        sx={{
          mt: { xs: "24px", md: "48px" },
          borderRadius: "20px",
        }}
        width="100%"
        height="100%"
        border="0"
        src={googleMapsSrc}
      />
    </Box>
  );
}

export default GoogleMapsSection;
