import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import DrAnneNoBackground from "./assets/drannenobackground.png"; // Ensure the correct path
// import ProfileSection from "./ProfileSection";
import { Link } from "react-router-dom";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";

const Welcome = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3, mb: "120px" }}>
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "center",
            mt: 5,
            padding: { xs: 0, md: "64px 100px" },
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: "9vw", md: 46, lg: 64 },
                mb: 2,
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Board Certified
            </Typography>
            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontWeight: "bold",
                mb: 2,
                textAlign: { xs: "center", md: "left" },
                fontSize: { xs: "9vw", md: 46, lg: 64 },
              }}
            >
              Clinical Sexologist
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mb: 3, textAlign: { xs: "center", md: "left" } }}
            >
              Dr. Rothenberg is dedicated to addressing sexual dysfunction with
              expertise and sensitivity. Embrace a fulfilling and healthy sexual
              life.
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile, horizontally on desktop
                justifyContent: { xs: "center", md: "flex-start" },
                alignItems: { xs: "stretch", md: "center" }, // Stretch buttons to full width on mobile
                gap: 2,
                mt: 2, // Additional margin top for spacing
                mb: { xs: "32px", md: 0 }, // Margin bottom
              }}
            >
              <Button
                component={Link}
                to="/request-consultation"
                variant="contained"
                sx={{
                  color: "black",
                  backgroundColor: "#E4B5A2",
                  fontSize: { xs: 16, md: 12, lg: 16 },
                  padding: "8px 14px",
                  borderRadius: "6px",
                  boxShadow: "none",
                  textTransform: "none", // Prevents text from being all uppercase
                  width: { xs: "100%", md: "auto" }, // Full width on mobile, auto width on desktop
                  "&:hover": {
                    backgroundColor: "#FFB58A",
                    boxShadow: "none",
                  },
                }}
              >
                <ForumOutlinedIcon sx={{ mr: "4px" }} />
                Request a Consultation
              </Button>
              <Button
                component="a"
                href="tel:407-797-5469"
                variant="outlined"
                sx={{
                  color: "black",
                  borderColor: "#E4B5A2",
                  padding: "8px 14px",
                  borderRadius: "6px",
                  fontSize: { xs: 16, md: 12, lg: 16 },
                  textTransform: "none",
                  width: { xs: "100%", md: "auto" },
                  "&:hover": {
                    backgroundColor: "#FFB58A",
                    borderColor: "#FFB58A",
                    color: "#FFFFFF",
                  },
                }}
              >
                <PhoneOutlinedIcon sx={{ mr: "4px" }} />
                Call me Directly
              </Button>

              <Button
                component="a"
                href="sms:407-797-5469?body=Hello%20Dr.%20Anne%20Rothenberg,%20I%27d%20like%20to%20inquire%20about..."
                variant="outlined"
                sx={{
                  color: "black",
                  borderColor: "#E4B5A2",
                  padding: "8px 14px",
                  borderRadius: "6px",
                  fontSize: { xs: 16, md: 12, lg: 16 },
                  textTransform: "none",
                  width: { xs: "100%", md: "auto" },
                  "&:hover": {
                    backgroundColor: "#FFB58A",
                    borderColor: "#FFB58A",
                    color: "#FFFFFF",
                  },
                }}
              >
                <MessageOutlinedIcon sx={{ mr: "4px" }} />
                Text me Directly
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "relative",
                width: 300,
                height: 300,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  border: "2px solid #FFB58A",
                  boxSizing: "border-box",
                  top: 0,
                  left: 0,
                  zIndex: -1,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  width: "90%",
                  height: "90%",
                  borderRadius: "50%",
                  bgcolor: "#FFB58A",
                  top: "5%",
                  left: "5%",
                  zIndex: -1,
                }}
              />
              <img
                src={DrAnneNoBackground}
                alt="Dr. Rothenberg"
                style={{
                  width: "80%",
                  height: "auto",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "10%",
                  left: "10%",
                  zIndex: 1,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <ProfileSection /> */}
    </>
  );
};

export default Welcome;
