import React, { useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Button,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Divider,
  Link,
} from "@mui/material";
import AetnaLogo from "./logos/aetna.png";
import CignaLogo from "./logos/cigna.png";
import OscarLogo from "./logos/oscar.png";
import OxfordLogo from "./logos/oxford.png";
import UnitedLogo from "./logos/united.png";
import { useNavigate } from "react-router-dom"; // Assuming you are using react-router v6
import Understand from "../common/Understand";
import CustomHelmet from "../common/CustomHelmet";

function PricingAndInsurance() {
  const [selectedInsurance, setSelectedInsurance] = useState("");
  const navigate = useNavigate();

  const handleSelectInsurance = (insurance) => {
    setSelectedInsurance(insurance);
  };

  return (
    <>
      <CustomHelmet
        title="Explore Insurance Options for Therapy with Dr. Anne Rothenberg"
        description="Find the best insurance coverage for your therapy sessions with Dr. Anne Rothenberg. Check eligibility for Aetna, Cigna, Oscar, and more. Affordable self-pay options available."
        keywords="Dr. Anne Rothenberg insurance, therapy insurance coverage, Aetna, Cigna, Oscar health insurance, affordable therapy options, therapy without insurance"
        ogTitle="Insurance Options for Therapy - Dr. Anne Rothenberg"
        ogDescription="Explore insurance and self-pay options for your therapy sessions with Dr. Anne Rothenberg. Get covered with Aetna, Cigna, Oscar, and more."
        ogUrl="https://drannerothenberg.com/insurance"
      />
      <Container maxWidth="lg" style={{ padding: "40px 16px" }}>
        <Typography
          variant="h1"
          sx={{
            mb: { xs: "40px", sm: "72px" },
          }}
        >
          Insurance Options
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{ marginBottom: "30px" }}
        >
          Select your insurance. Don't see yours?{" "}
          <Link
            component="button"
            variant="body1"
            onClick={() => navigate("/rates")}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            View my Self-Pay options
          </Link>
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {[
            { name: "Aetna", logo: AetnaLogo },
            { name: "Cigna", logo: CignaLogo },
            { name: "Oscar", logo: OscarLogo },
            { name: "Oxford", logo: OxfordLogo },
            { name: "United", logo: UnitedLogo },
          ].map((insurance) => (
            <Grid item xs={6} sm={4} md={2} lg={2} key={insurance.name}>
              <Card
                sx={{
                  maxWidth: 345,
                  border:
                    selectedInsurance === insurance.name
                      ? "2px solid #E4B5A2"
                      : "none",
                  boxShadow: selectedInsurance === insurance.name ? 3 : 1,
                  cursor: "pointer",
                  transition: "box-shadow 0.3s",
                }}
                onClick={() => handleSelectInsurance(insurance.name)}
              >
                <CardActionArea>
                  {insurance.logo ? (
                    <CardMedia
                      component="img"
                      image={insurance.logo}
                      alt={insurance.name}
                      sx={{ height: "auto", padding: 1 }}
                    />
                  ) : (
                    <Typography
                      variant="h5"
                      component="div"
                      align="center"
                      sx={{ my: 3 }}
                    >
                      {insurance.name}
                    </Typography>
                  )}
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>

        {selectedInsurance && (
          <Box mt={4}>
            {selectedInsurance !== "None of the Above" ? (
              <Grid
                container
                spacing={2}
                justifyContent="center"
                direction="row"
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                      padding: "0 40px",
                    }}
                  >
                    I have partnered with Alma to help you check your{" "}
                    {selectedInsurance} coverage. Enter your details on their
                    site to find out what your costs will be. If you're ready to
                    get started using insurance, please request a consultation
                    through Alma.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        (window.location.href = `https://secure.helloalma.com/check-my-insurance-coverage/?provider=anne-rothenberg`)
                      }
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#E4B5A2",
                        boxShadow: "none",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#FFB58A",
                          boxShadow: "none",
                        },
                        width: { xs: "80%", sm: "auto" },
                      }}
                    >
                      Check Cost Estimate
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() =>
                        (window.location.href = `https://secure.helloalma.com/providers/anne-rothenberg/`)
                      }
                      sx={{
                        mt: { xs: 1, sm: 0 },
                        textTransform: "none",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                        color: "black",
                        border: "1px solid #E4B5A2",
                        "&:hover": {
                          backgroundColor: "#FFB58A",
                          borderColor: "#FFB58A",
                          color: "#FFFFFF",
                          boxShadow: "none",
                        },
                        width: { xs: "80%", sm: "auto" },
                      }}
                    >
                      Request a Consultation thru Alma
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate("/rates")}
                    sx={{ textTransform: "none" }}
                  >
                    View Self-Pay Options
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        )}

        <Divider sx={{ my: 4 }} />
        <Understand />
      </Container>
    </>
  );
}

export default PricingAndInsurance;
