import React from "react";
import { TextField, MenuItem } from "@mui/material";

function BasicInfoFields({ formData, handleChange }) {
  return (
    <>
      <TextField
        label="First name"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Last name"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Virtual or in-person?"
        name="preference"
        select
        value={formData.preference}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="virtual">Virtual</MenuItem>
        <MenuItem value="inPerson">In-Person</MenuItem>
      </TextField>
      <TextField
        label="What are you seeking help with?"
        name="reason"
        value={formData.reason}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        margin="normal"
      />
    </>
  );
}

export default BasicInfoFields;
