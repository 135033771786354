import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";

function ContactInfo() {
  return (
    <Box
      sx={{
        textAlign: "center",
        marginTop: 4,
        padding: 4,
      }}
    >
      <Typography variant="h6" component="p" gutterBottom>
        Take the next step.
      </Typography>
      <Typography variant="body1" component="p" gutterBottom>
        A better life is just one phone call, email, or consultation away.
      </Typography>
      <Typography variant="h4" component="p" gutterBottom>
        I understand.
        <br />
        I care.
        <br />I can help.
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile, horizontally on desktop
          justifyContent: { xs: "center" },
          alignItems: { xs: "stretch", md: "center" }, // Stretch buttons to full width on mobile
          gap: 2,
          mt: 4,
        }}
      >
        <Button
          component={Link}
          to="/request-consultation"
          variant="contained"
          sx={{
            color: "black",
            backgroundColor: "#E4B5A2",
            fontSize: { xs: 16, md: 12, lg: 16 },
            padding: "8px 14px",
            borderRadius: "6px",
            boxShadow: "none",
            textTransform: "none", // Prevents text from being all uppercase
            width: { xs: "100%", md: "auto" }, // Full width on mobile, auto width on desktop
            "&:hover": {
              backgroundColor: "#FFB58A",
              boxShadow: "none",
            },
          }}
        >
          <ForumOutlinedIcon sx={{ mr: "4px" }} />
          Request a Consultation
        </Button>
        <Button
          component="a"
          href="tel:407-797-5469"
          variant="outlined"
          sx={{
            color: "black",
            borderColor: "#E4B5A2",
            padding: "8px 14px",
            borderRadius: "6px",
            fontSize: { xs: 16, md: 12, lg: 16 },
            textTransform: "none",
            width: { xs: "100%", md: "auto" },
            "&:hover": {
              backgroundColor: "#FFB58A",
              borderColor: "#FFB58A",
              color: "#FFFFFF",
            },
          }}
        >
          <PhoneOutlinedIcon sx={{ mr: "4px" }} />
          Call me Directly
        </Button>

        <Button
          component="a"
          href="sms:407-797-5469?body=Hello%20Dr.%20Anne%20Rothenberg,%20I%27d%20like%20to%20inquire%20about..."
          variant="outlined"
          sx={{
            color: "black",
            borderColor: "#E4B5A2",
            padding: "8px 14px",
            borderRadius: "6px",
            fontSize: { xs: 16, md: 12, lg: 16 },
            textTransform: "none",
            width: { xs: "100%", md: "auto" },
            "&:hover": {
              backgroundColor: "#FFB58A",
              borderColor: "#FFB58A",
              color: "#FFFFFF",
            },
          }}
        >
          <MessageOutlinedIcon sx={{ mr: "4px" }} />
          Text me Directly
        </Button>
      </Box>
    </Box>
  );
}

export default ContactInfo;
