import React from "react";
import { Helmet } from "react-helmet";

const CustomHelmet = ({
  title,
  description,
  keywords,
  ogTitle,
  ogDescription,
  ogUrl,
}) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta property="og:title" content={ogTitle} />
    <meta property="og:description" content={ogDescription} />
    <meta property="og:url" content={ogUrl} />
  </Helmet>
);

export default CustomHelmet;
