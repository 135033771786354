// useAccordion.js
import { useState } from "react";

export function useAccordion() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return { expanded, handleChange };
}
