import React from "react";
import { Box, Typography, Link } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";

function UtilityNav() {
  return (
    <Box
      sx={{
        width: "100%",
        padding: 1,
        backgroundColor: "#F8E2DD",
        overflowX: "hidden",
        boxSizing: "border-box", // Ensures padding doesn't affect the total width of the box
      }}
    >
      {/* Desktop Version */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "7%", // Added left margin
          marginRight: "7%", // Added right margin
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LocationOnOutlinedIcon sx={{ color: "black", marginRight: 1 }} />
          <a
            href="https://maps.app.goo.gl/cWZaSH3NGqFiBY6o9"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body2" sx={{ color: "black" }}>
              2700 Westhall Ln Ste 203, Maitland, FL 32751
            </Typography>
          </a>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailOutlinedIcon sx={{ color: "black", marginRight: 1 }} />
          <Link
            href="mailto:rothenbergcounseling@gmail.com"
            sx={{
              color: "black",
              textDecoration: "none",
              marginRight: "50px",
            }}
          >
            rothenbergcounseling@gmail.com
          </Link>
          <PhoneOutlinedIcon sx={{ color: "black", marginRight: 1 }} />
          <Link
            href="tel:+14077975469"
            sx={{ color: "black", textDecoration: "none" }}
          >
            407-797-5469
          </Link>
        </Box>
      </Box>

      {/* Mobile Version */}
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "row", // Maintain row for horizontal layout
          alignItems: "center",
          justifyContent: "space-around", // Evenly space the icons across the horizontal axis
          width: "100%", // Ensure the box uses the full width for even spacing
          position: "relative",
        }}
      >
        <Link
          href="https://maps.app.goo.gl/cWZaSH3NGqFiBY6o9"
          sx={{ display: "flex" }}
        >
          <LocationOnOutlinedIcon sx={{ color: "black" }} />
        </Link>
        <Link
          href="mailto:rothenbergcounseling@gmail.com"
          sx={{ display: "flex" }}
        >
          <EmailOutlinedIcon sx={{ color: "black" }} />
        </Link>
        <Link href="tel:+14077975469" sx={{ display: "flex" }}>
          <PhoneOutlinedIcon sx={{ color: "black" }} />
        </Link>
      </Box>
    </Box>
  );
}

export default UtilityNav;
