import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";
import CustomHelmet from "../../common/CustomHelmet";

const FemaleSexualDysfunctionDetails = () => {
  return (
    <>
      <CustomHelmet
        title="Female Sexual Dysfunction Therapy with Dr. Anne Rothenberg"
        description="Dr. Anne Rothenberg specializes in counseling for female sexual dysfunction, addressing issues like decreased desire, performance anxiety, and effects of past trauma. Discover a supportive path to improving sexual health and well-being."
        keywords="female sexual dysfunction, sexual health therapy, Dr. Anne Rothenberg sexologist, performance anxiety therapy, overcoming sexual trauma, improving sexual desire"
        ogTitle="Overcome Female Sexual Dysfunction with Expert Therapy"
        ogDescription="Expert therapy for female sexual dysfunction with Dr. Anne Rothenberg. Addressing performance anxiety, decreased desire, and more for better sexual health."
        ogUrl="https://drannerothenberg.com/services/female-sexual-dysfunction"
      />
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Female Sexual Dysfunction
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you experiencing any of the following?
          </Typography>
        </Box>
        <List
          dense
          sx={{
            listStyleType: "disc",
            pl: 4,
            color: "#333",
            textAlign: "left",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            Loss of interest in sexual activities with your partner/spouse?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Difficulty in achieving sexual compatibility?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Emotional or physical withdrawal from your partner/spouse?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Challenges in reaching orgasm?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Reliance on online pornography or masturbation as your primary
            sexual outlet?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Anxiety related to your sexual performance or abilities?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Lingering effects from past sexual abuse or trauma?
          </ListItem>
        </List>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1" gutterBottom sx={{ color: "#555" }}>
            Dr. Anne Rothenberg, an internationally recognized Clinical
            Sexologist and Therapist, specializes in helping women overcome
            non-physiological sexual performance issues, reducing sexual anxiety
            and increasing sexual desire.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "#4A90E2", mt: 2 }}
          >
            Areas Discussed in Counseling
          </Typography>
          <List
            dense
            sx={{
              listStyleType: "circle",
              pl: 4,
              color: "#333",
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              Sexual Dysfunction
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Inability to Orgasm
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Decreased Sexual Desire
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Diminished Sexual Arousal
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Loss of Sexual Pleasure
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Loss of Interest in Sex
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Sexual Discomfort/Pain
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Performance Anxiety
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Excessive Masturbation
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Excessive Pornography Usage
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Sexual Incompatibility
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Sexual Avoidance</ListItem>
            <ListItem sx={{ display: "list-item" }}>General Anxiety</ListItem>
            <ListItem sx={{ display: "list-item" }}>Worry</ListItem>
            <ListItem sx={{ display: "list-item" }}>Depression</ListItem>
            <ListItem sx={{ display: "list-item" }}>Sadness</ListItem>
            <ListItem sx={{ display: "list-item" }}>Hopelessness</ListItem>
            <ListItem sx={{ display: "list-item" }}>Loneliness</ListItem>
            <ListItem sx={{ display: "list-item" }}>Guilt</ListItem>
            <ListItem sx={{ display: "list-item" }}>Shame</ListItem>
            <ListItem sx={{ display: "list-item" }}>Body Image Issues</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Self-Esteem Issues
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Relationship Difficulties
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Intimacy Issues</ListItem>
          </List>
        </Box>
      </Container>
    </>
  );
};

export default FemaleSexualDysfunctionDetails;
