import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
} from "@mui/material";
import { categories } from "./servicesData";

const TestSidebar = () => {
  const location = useLocation();

  // Function to generate URL-friendly paths
  const generatePath = (label) => `/${label.toLowerCase().replace(/ /g, "-")}`;

  return (
    <div style={{ width: "auto", backgroundColor: "#fcf7f1" }}>
      {Object.entries(categories).map(([categoryName, services], idx) => (
        <React.Fragment key={idx}>
          <List
            subheader={
              <ListSubheader
                sx={{ textAlign: "left", backgroundColor: "#fcf7f1" }}
              >
                {categoryName} Services
              </ListSubheader>
            }
          >
            {services.map((service, index) => {
              const path = `/services${generatePath(service.label)}`;
              const isSelected = location.pathname === path;

              return (
                <ListItemButton
                  component={Link}
                  to={path}
                  key={index}
                  sx={{
                    backgroundColor: isSelected ? "#e0e0e0" : "inherit",
                    borderRadius: isSelected ? "8px" : "0",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <ListItemIcon>
                    <service.Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={service.label}
                    sx={{
                      color: isSelected ? "#000" : "#555",
                      fontWeight: isSelected ? "bold" : "normal",
                    }}
                  />
                </ListItemButton>
              );
            })}
          </List>
          {idx < Object.keys(categories).length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default TestSidebar;
