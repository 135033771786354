import React, { useRef, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { categories } from "./servicesData";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight"; // Import the right-chevron icon

const ServicesLandingPage = () => {
  const cardRefs = useRef([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [minHeight, setMinHeight] = React.useState(isMobile ? 200 : 350);

  useEffect(() => {
    const maxCardHeight = Math.max(
      ...cardRefs.current.map((cardRef) => (cardRef ? cardRef.offsetHeight : 0))
    );
    setMinHeight(maxCardHeight);
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2} // This controls the space between grid items
        sx={{
          width: "auto",
          // TODO: Uncomment the padding property below
          // When we get rid of the old pages.
          // We have too much padding when combined
          // padding: { xs: "0 16.5px", md: "0 100px" },
          mb: { xs: "50px", md: "100px" },
        }}
      >
        {Object.entries(categories).map(([categoryName, services], index) => (
          <Grid
            item
            xs={12}
            key={categoryName}
            sx={{ mb: index === 0 ? { xs: "50px", md: "75px" } : 0 }} // Add margin-bottom for the first item
          >
            <Typography
              variant="h6"
              sx={{
                mb: { xs: "24px", md: "48px" },
                fontFamily: "Roboto",
                fontSize: { xs: 24, md: 48 },
                fontWeight: 700,
                lineHeight: "57.6px",
                textAlign: "center",
              }}
            >
              {categoryName} Services
            </Typography>
            <Grid container spacing={2}>
              {services.map((service, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Card
                    ref={(el) => (cardRefs.current[index] = el)}
                    component={Link}
                    to={`/services/${service.label
                      .toLowerCase()
                      .replace(/ /g, "-")}`}
                    sx={{
                      opacity: 1, // Ensure the cards are visible
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "14px", // Styling the card
                      background: "#FBF1EF",
                      minHeight: `${minHeight}px`, // Set the minimum height
                      boxShadow: "none",
                      textDecoration: "none", // Remove the underline from the link
                      color: "inherit", // Inherit the color from the parent
                      "&:hover": {
                        background: "#EDE0DD", // Change the background color on hover
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex", // Use flexbox for layout
                        flexDirection: "column", // Stack children vertically
                        alignItems: "flex-start", // Align items to the start (left)
                        textAlign: "left", // Align text to the left
                        padding: { xs: "16px", md: "32px" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start", // Align items vertically at the start
                          justifyContent: "space-between", // Add space between the items
                          width: "100%", // Make the Box take up the full width
                        }}
                      >
                        {typeof service.Icon === "string" ? (
                          <img
                            src={service.Icon}
                            alt={service.label}
                            style={{
                              width: isMobile ? "48px" : "88px", // Set the width based on screen size
                              height: "auto", // Set the height
                              marginBottom: "16px", // Adds 16px margin below the title
                              backgroundColor: "#F8E2DD", // Make the background transparent
                              borderRadius: "6px",
                            }}
                          />
                        ) : (
                          <service.Icon
                            sx={{
                              fontSize: { xs: 48, md: 88 },
                              marginBottom: 4, // Adds 16px margin below the title (2 * theme spacing unit which is typically 8px)
                              backgroundColor: "#F8E2DD", // Make the background transparent
                              borderRadius: "6px",
                            }}
                          />
                        )}
                        <ChevronRightIcon
                          sx={{
                            fontSize: { xs: 14, md: 24 },
                          }}
                        />
                      </Box>

                      {/* Icon from the service data */}
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: 20,
                          fontWeight: 700,
                          lineHeight: "24px",
                          textAlign: "left",
                          marginBottom: 2, // Adds 16px margin below the title (2 * theme spacing unit which is typically 8px)
                        }}
                      >
                        {service.label} {/* Title from the service data */}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: 16,
                          fontWeight: 400,
                          lineHeight: "19.2px",
                          textAlign: "left",
                        }}
                      >
                        {service.description}{" "}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ServicesLandingPage;
