import ComputerIcon from "@mui/icons-material/Computer";

import {
  PrideFlagIcon,
  TransgenderFlagIcon,
  AutismInfinityIcon,
} from "../../common/RainbowIcon";
import rings from "../assets/rings.png";
import sexTherapyIcon from "../assets/sex-therapy-icon.png";

// This file contains the data for the services on the homepage
// This is different since there are no categories on the homepage

export const categories = {
  Individual: [
    {
      label: "Online Sexual Addiction",
      description:
        "Addresses compulsive behaviors and challenges associated with online sexual activities, helping individuals regain control and improve their relational health.",
      Icon: ComputerIcon,
    },
    {
      label: "Marriage Counseling",
      description:
        "Facilitates stronger relationships through improved communication, conflict resolution, and enhanced intimacy and understanding between partners.",
      Icon: rings,
    },
    {
      label: "Sex Therapy for Couples",
      description:
        "Specifically addresses sexual issues within relationships, promoting healthier sexual communication, understanding, and satisfaction.",
      Icon: sexTherapyIcon,
    },
    {
      label: "LGBTQ Counseling",
      description:
        "Provides a safe space for exploring gender and sexual identity issues, offering support for related emotional and social challenges.",
      Icon: PrideFlagIcon,
    },
    {
      label: "Transgender Counseling",
      description:
        "Provides support for individuals exploring or undergoing gender transition, including emotional support, navigating social changes, and accessing medical resources.",
      Icon: TransgenderFlagIcon,
    },
    {
      label: "Autism Support for Adults",
      description:
        "Specialized support addressing the unique life and social challenges faced by autistic adults, including workplace success, social relationships, and personal development.",
      Icon: AutismInfinityIcon,
    },
  ],
};

export const services = Object.entries(categories).flatMap(
  ([category, services]) =>
    services.map((service) => ({
      ...service,
      category,
    }))
);
