import React from "react";
import ProfileSection from "./ProfileSection";
import ContactSection from "./Contact";
import GoogleMapsSection from "../common/Map";
import CustomHelmet from "../common/CustomHelmet";
import Welcome from "./Welcome";
import ServicesChips from "./ServiceChips";
import FAQPage from "./FAQ/FAQ";

function Home() {
  return (
    <>
      <CustomHelmet
        title="Dr. Anne Rothenberg - Marriage and Couples Sexologist in Maitland, FL"
        description="Dr. Anne Rothenberg, Ph.D., LMHC 14391 offers compassionate, personalized counseling to enhance relationships and well-being."
        keywords="Orlando marriage counseling, LGBTQ+ therapy Orlando, Dr. Anne Rothenberg, porn addiction therapy, narcissism counseling, sexologist in Orlando, Cigna health insurance counseling"
        ogTitle="Dr. Anne Rothenberg - Board Certified Clinical Sexologist"
        ogDescription="Board Certified Clinical Sexologist and Licensed Mental Health Counselor specializing in couples therapy and sexual health in Maitland, FL."
        ogUrl="https://drannerothenberg.com/"
      />
      <Welcome />
      <ServicesChips />
      <ProfileSection />
      <ContactSection />
      <FAQPage />
      <GoogleMapsSection />
    </>
  );
}

export default Home;
