import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";
import CustomHelmet from "../../common/CustomHelmet";

const MaleSexualDysfunctionDetails = () => {
  return (
    <>
      <CustomHelmet
        title="Male Sexual Dysfunction Therapy with Dr. Anne Rothenberg"
        description="Dr. Anne Rothenberg specializes in treating male sexual dysfunction, helping to overcome issues like performance anxiety, erectile dysfunction, and decreased libido. Learn more about our compassionate and confidential treatment options."
        keywords="male sexual dysfunction, erectile dysfunction therapy, performance anxiety help, Dr. Anne Rothenberg sexual health, premature ejaculation treatment, men's sexual health"
        ogTitle="Effective Therapy for Male Sexual Dysfunction - Dr. Anne Rothenberg"
        ogDescription="Explore specialized therapy for male sexual dysfunction with Dr. Anne Rothenberg. Address issues such as erectile dysfunction, premature ejaculation, and more."
        ogUrl="https://drannerothenberg.com/services/male-sexual-dysfunction"
      />
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Male Sexual Dysfunction
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you experiencing any of the following?
          </Typography>
        </Box>
        <List
          dense
          sx={{
            listStyleType: "disc",
            pl: 4,
            color: "#333",
            textAlign: "left",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            Difficulty obtaining or maintaining erections?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Premature ejaculation?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Has online pornography or masturbation become your primary sexual
            outlet?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Anxiety related to your sexual performance?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Sexual or emotional withdrawal from your spouse/partner?
          </ListItem>
        </List>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1" gutterBottom sx={{ color: "#555" }}>
            Dr. Anne Rothenberg specializes in helping men with
            non-physiological sexual performance issues to reduce their
            performance anxiety and their dependence upon masturbation and
            pornography. Dr. Anne Rothenberg has helped countless individuals
            and couples to enjoy their sexuality and to start living happier
            lives.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "#4A90E2", mt: 2 }}
          >
            Areas Discussed in Counseling
          </Typography>
          <List
            dense
            sx={{
              listStyleType: "circle",
              pl: 4,
              color: "#333",
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              Sexual Dysfunction
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Premature Ejaculation
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Delayed Ejaculation
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Inability to Orgasm
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Inability to Ejaculate During Intercourse
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Decreased Sexual Desire
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Diminished Sexual Arousal
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Loss of Sexual Pleasure
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Loss of Interest in Sex
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Performance Anxiety
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Difficulty Obtaining Erections
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Difficulty Maintaining Erections
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Reduced Erection Strength/Hardness
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Excessive Masturbation
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Excessive Pornography Usage
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Sexual Incompatibility
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Sexual Avoidance</ListItem>
            <ListItem sx={{ display: "list-item" }}>General Anxiety</ListItem>
            <ListItem sx={{ display: "list-item" }}>Worry</ListItem>
            <ListItem sx={{ display: "list-item" }}>Depression</ListItem>
            <ListItem sx={{ display: "list-item" }}>Sadness</ListItem>
            <ListItem sx={{ display: "list-item" }}>Hopelessness</ListItem>
            <ListItem sx={{ display: "list-item" }}>Loneliness</ListItem>
            <ListItem sx={{ display: "list-item" }}>Guilt</ListItem>
            <ListItem sx={{ display: "list-item" }}>Shame</ListItem>
            <ListItem sx={{ display: "list-item" }}>Body Image Issues</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Self-Esteem Issues
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Relationship Difficulties
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Intimacy Issues</ListItem>
          </List>
        </Box>
      </Container>
    </>
  );
};

export default MaleSexualDysfunctionDetails;
