import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { services } from "./Services/servicesDataHome"; // Adjust the path as necessary
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight"; // Import the right-chevron icon

function MyGridComponent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const cardRefs = useRef([]);
  const [minHeight, setMinHeight] = useState(0);

  // Determine the number of cards to display based on screen size
  const displayedServices = isMobile
    ? services.slice(0, 4)
    : services.slice(0, 6);

  useEffect(() => {
    const maxCardHeight = Math.max(
      ...cardRefs.current.map((cardRef) => (cardRef ? cardRef.offsetHeight : 0))
    );
    setMinHeight(maxCardHeight);
  }, [displayedServices]);

  return (
    <Box>
      <Typography
        variant="h3"
        sx={{
          fontSize: { xs: 36, md: 46 },
          fontWeight: 700,
          textAlign: "center",
          mb: "60px",
        }}
      >
        My Services
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ width: "auto", padding: { xs: "0 16.5px", md: "0 100px" } }}
      >
        {displayedServices.map((service, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card
              ref={(el) => (cardRefs.current[index] = el)}
              component={Link}
              to={`/services/${service.label.toLowerCase().replace(/ /g, "-")}`}
              sx={{
                opacity: 1,
                display: "flex",
                flexDirection: "column",
                borderRadius: "14px",
                background: "#FBF1EF",
                minHeight: `${minHeight}px`,
                boxShadow: "none",
                textDecoration: "none",
                color: "inherit",
                "&:hover": {
                  background: "#EDE0DD",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex", // Use flexbox for layout
                  flexDirection: "column", // Stack children vertically
                  alignItems: "flex-start", // Align items to the start (left)
                  textAlign: "left", // Align text to the left
                  padding: { xs: "16px", md: "32px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start", // Align items vertically at the start
                    justifyContent: "space-between", // Add space between the items
                    width: "100%", // Make the Box take up the full width
                  }}
                >
                  {typeof service.Icon === "string" ? (
                    <img
                      src={service.Icon}
                      alt={service.label}
                      style={{
                        width: isMobile ? "48px" : "88px",
                        height: "auto",
                        marginBottom: "32px",
                        backgroundColor: "#F8E2DD",
                        borderRadius: "6px",
                      }}
                    />
                  ) : (
                    <service.Icon
                      sx={{
                        fontSize: { xs: 48, md: 88 },
                        marginBottom: 4,
                        backgroundColor: "#F8E2DD",
                        borderRadius: "6px",
                      }}
                    />
                  )}
                  <ChevronRightIcon
                    sx={{
                      fontSize: { xs: 14, md: 24 },
                    }}
                  />
                </Box>

                {/* Icon from the service data */}
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: 20,
                    fontWeight: 700,
                    lineHeight: "24px",
                    textAlign: "left",
                    marginBottom: 2,
                  }}
                >
                  {service.label} {/* Title from the service data */}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: "19.2px",
                    textAlign: "left",
                  }}
                >
                  {service.description}{" "}
                  {/* Description from the service data */}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button
        component={Link}
        to="/services"
        variant="contained"
        sx={{
          color: "black",
          backgroundColor: "#E4B5A2",
          padding: "8px 14px",
          borderRadius: "6px",
          boxShadow: "none",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#FFB58A",
            boxShadow: "none",
          },
          m: 4,
        }}
      >
        View all Services
      </Button>
    </Box>
  );
}

export default MyGridComponent;
