import React, { useEffect } from "react";

function NotFoundPage() {
  useEffect(() => {
    // Create meta tag
    const metaTag = document.createElement("meta");
    metaTag.name = "prerender-status-code";
    metaTag.content = "404";
    document.getElementsByTagName("head")[0].appendChild(metaTag);

    // Cleanup function to remove meta tag when component unmounts
    return () => {
      document.getElementsByTagName("head")[0].removeChild(metaTag);
    };
  }, []);

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}

export default NotFoundPage;
