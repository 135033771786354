import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";
import CustomHelmet from "../../common/CustomHelmet";

const MarriageCounseling = () => {
  return (
    <>
      <CustomHelmet
        title="Marriage and Relationship Counseling - Dr. Anne Rothenberg"
        description="Dr. Anne Rothenberg offers expert marriage and relationship counseling to help couples overcome challenges, improve communication, and strengthen their bond."
        keywords="marriage counseling, relationship counseling, couples therapy, communication improvement, intimacy issues, conflict resolution, Dr. Anne Rothenberg, Maitland FL"
        ogTitle="Marriage and Relationship Counseling - Dr. Anne Rothenberg"
        ogDescription="Expert marriage and relationship counseling to help couples overcome challenges, improve communication, and strengthen their bond."
        ogUrl="https://drannerothenberg.com/services/marriage-counseling"
      />
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Marriage and Relationship Counseling
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ color: "#555" }}>
            Dr. Anne Rothenberg offers expert marriage and relationship
            counseling to help couples overcome challenges, improve
            communication, and strengthen their bond. Whether you're dealing
            with financial stress, intimacy issues, or conflicts in parenting,
            Dr. Rothenberg provides a safe and supportive environment to address
            your concerns and find solutions.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "#4A90E2", mt: 2 }}
          >
            Common Issues Addressed in Marriage Counseling
          </Typography>
          <List
            dense
            sx={{
              listStyleType: "disc",
              pl: 4,
              color: "#333",
              textAlign: "left",
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              We have money problems
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              My partner doesn’t understand me
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I’m too tired for sex
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              My partner doesn’t help me
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I have to do all the work
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I’m not interested in sex like I used to be
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              My partner never initiates sex
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I’m tired of being rejected
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              We had a baby and we don’t have sex anymore
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              We’re living beyond our means
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              We don’t agree on how to raise our children
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              We have the same fight over and over
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              We have nothing in common
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              My partner is too demanding
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              My partner doesn’t seem to have time for me
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              My partner is not attracted to me anymore
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I’m not attracted to my partner anymore
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              My partner has cheated
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I can’t trust my partner anymore
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I’m attracted to someone else
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I don’t desire my partner
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I don’t think I’m in love anymore
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>I’m not aroused</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I don’t have orgasms
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              We have issues with family members
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              The in-laws have come between us
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              We never have any privacy
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              There never seems to be enough time
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              We have busy schedules
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I have problems at work
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              My partner works all the time
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              I can’t live like this anymore
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              We want things to be better between us
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Intimacy Issues</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Conflict Resolution
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Building Trust and Honesty
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Improving Communication Skills
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Rekindling Romance
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Parenting Challenges
            </ListItem>
          </List>
        </Box>
      </Container>
    </>
  );
};

export default MarriageCounseling;
