import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";
import CustomHelmet from "../../common/CustomHelmet";

const SexTherapyForCouples = () => {
  return (
    <>
      <CustomHelmet
        title="Couple's Sex Therapy - Dr. Anne Rothenberg"
        description="Expert couple's sex therapy with Dr. Anne Rothenberg. Address issues like mismatched desires, erectile dysfunction, and communication problems to enhance intimacy and sexual satisfaction."
        keywords="sex therapy for couples, couple's sexual health, sexual satisfaction counseling, Orlando sex therapy, unhappy marriage, sexologist, sexless marriage help"
        ogTitle="Improve Your Intimate Relationship with Couple's Sex Therapy"
        ogDescription="Dr. Anne Rothenberg provides specialized sex therapy for couples looking to overcome challenges and enhance their intimate connections. Explore therapy options today."
        ogUrl="https://drannerothenberg.com/services/sex-therapy-for-couples"
      />
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Sex Therapy for Couples
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ color: "#555" }}>
            Dr. Anne Rothenberg provides specialized sex therapy for couples to
            help them overcome sexual challenges and enhance their intimate
            relationships. Whether you are dealing with mismatched sexual
            desires, performance anxiety, or communication issues around sex,
            Dr. Rothenberg offers a supportive and non-judgmental environment to
            address your concerns and improve your sexual satisfaction.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "#4A90E2", mt: 2 }}
          >
            Common Issues Addressed in Sex Therapy for Couples
          </Typography>
          <List
            dense
            sx={{
              listStyleType: "disc",
              pl: 4,
              color: "#333",
              textAlign: "left",
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              Mismatched Sexual Desires
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Performance Anxiety
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Erectile Dysfunction
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Premature Ejaculation
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Inability to Orgasm
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Painful Intercourse
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Loss of Sexual Desire
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Sexual Incompatibility
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Emotional Disconnect During Sex
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Sexual Boredom</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Communication Issues Around Sex
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Intimacy Issues</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Recovering from Infidelity
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Rekindling Sexual Passion
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Navigating Sexual Fantasies and Preferences
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Body Image Issues Affecting Sexuality
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Sexual Trauma and Healing
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Improving Sexual Communication
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Exploring New Sexual Experiences
            </ListItem>
          </List>
        </Box>
      </Container>
    </>
  );
};

export default SexTherapyForCouples;
