import React from "react";
import { Grid, Box, Typography, Paper } from "@mui/material";
import DrAnneImage from "./assets/dranne-hq-updated.jpg"; // Replace with the correct path to the image
import Divider from "@mui/material/Divider";

const ProfileSection = () => {
  return (
    <Paper
      elevation={0}
      sx={{ margin: { xs: "20px", md: "40px", lg: "120px 185px" } }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            sx={{
              width: "auto",
              height: { xs: 320, md: 460 },
              borderRadius: "20px",
            }}
            src={DrAnneImage}
            alt="Dr. Anne Rothenberg"
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: 700, fontSize: { xs: "24px ", md: "32px" } }}
          >
            Dr. Anne Rothenberg
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Ph.D., LMHC 14391
          </Typography>

          <Typography
            variant="subtitle2"
            color="textSecondary"
            gutterBottom
            sx={{
              fontFamily: "Roboto",
              fontSize: { xs: 10, md: 15 },
              fontWeight: 400,
              lineHeight: { xs: "15px", md: "24px" },
              textAlign: { xs: "left" },
            }}
          >
            Licensed Mental Health Counselor | Board Certified Clinical
            Sexologist
          </Typography>

          <Divider sx={{ mt: "16px", mb: "16px" }} />

          <Typography variant="body1" paragraph sx={{ fontWeight: "bold" }}>
            Meet Dr. Anne Rothenberg
          </Typography>

          <Typography
            variant="body2"
            paragraph
            sx={{
              fontSize: { xs: 14, md: 16 },
              fontFamily: "Roboto",
              fontWeight: 400,
              lineHeight: { xs: "21px", md: "24px" },
            }}
          >
            Dr. Anne Rothenberg is renowned for her warm, accepting, and
            non-judgmental approach. She creates a safe and welcoming
            environment where you can comfortably discuss sensitive issues. Her
            open and direct communication style ensures that individuals,
            couples, and families feel at ease addressing challenges that are
            often difficult to talk about.
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontWeight: "bold" }}>
            Expertise in Human Sexuality
          </Typography>
          <Typography
            variant="body2"
            paragraph
            sx={{
              fontSize: { xs: 14, md: 16 },
              fontFamily: "Roboto",
              fontWeight: 400,
              lineHeight: { xs: "21px", md: "24px" },
            }}
          >
            As a Board Certified Clinical Sexologist and a globally recognized
            expert in human sexuality and sexual behavior, Dr. Rothenberg offers
            specialized counseling in Transsexual and Transgender issues. She
            adheres to the highest standards of care set by the World
            Professional Association for Transgender Health (WPATH).
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProfileSection;
