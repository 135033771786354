import React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ForumIcon from "@mui/icons-material/Forum";
import { Link as RouterLink } from "react-router-dom";

function ContactSection() {
  return (
    <Box
      sx={{
        backgroundColor: "#FBF1EF", // light background color
        color: "black",
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{ mb: 1, marginTop: "15px", textAlign: "center" }}
      >
        Dr. Anne Rothenberg welcomes your questions.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        Please call my confidential voice mail or e-mail. Your privacy is{" "}
        <strong>always</strong> assured.
      </Typography>

      {/* Added Grid Section with updated styles for mobile and colors */}
      <Grid
        container
        spacing={2} // Increase spacing
        justifyContent="center"
        alignItems="center"
        width={"90%"}
        sx={{
          marginTop: 2, // Reduced margin top
          marginBottom: { xs: 4, md: 4 }, // Additional bottom margin on mobile
          "& .MuiGrid-item": {
            marginTop: { xs: 2, md: 0 }, // Additional top margin for mobile
            marginBottom: { xs: 2, md: 0 }, // Additional bottom margin for mobile
          },
        }}
      >
        <Grid item xs={12} md={4}>
          <Link href="tel:407-797-5469" color="inherit" underline="none">
            <PhoneIcon sx={{ fontSize: 40, color: "#708840" }} />
            <Typography variant="subtitle1" component="p" gutterBottom>
              407-797-5469
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link
            href="mailto:RothenbergCounseling@gmail.com"
            color="inherit"
            underline="none"
          >
            <EmailIcon sx={{ fontSize: 40, color: "#708840" }} />
            <Typography variant="subtitle1" component="p" gutterBottom>
              RothenbergCounseling@gmail.com
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <RouterLink
            to="/request-consultation"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ForumIcon sx={{ fontSize: 40, color: "#708840" }} />
            <Typography variant="subtitle1" component="p" gutterBottom>
              Request a consultation
            </Typography>
          </RouterLink>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactSection;
