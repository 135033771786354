import React from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../assets/logo_light.png";

const DesktopNav = () => {
  // Define common styles for the links
  const linkStyle = {
    color: "inherit", // Use the same color as the text
    textDecoration: "none", // No underline by default
    marginRight: "20px", // Spacing between items
    transition: "all 0.3s ease", // Smooth transition for hover effects
    "&:hover": {
      color: "#c62828", // Change color on hover
      borderRadius: "4px", // Optional: rounded corners for background
    },
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{ padding: "10px 20px", height: "80px" }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ml: { sm: "0%", md: "5%" },
          }}
        >
          <Link to="/" style={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ marginRight: "20px", height: "40px" }}
            />
          </Link>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mr: "5%" }}>
          <Typography component={Link} to="/" sx={linkStyle}>
            Home
          </Typography>
          <Typography component={Link} to="/services" sx={linkStyle}>
            Services
          </Typography>
          <Typography component={Link} to="/rates" sx={linkStyle}>
            Rates
          </Typography>
          <Typography component={Link} to="/insurance" sx={linkStyle}>
            Insurance
          </Typography>

          <Typography component={Link} to="/contact" sx={linkStyle}>
            Contact
          </Typography>
          <Button
            color="inherit"
            component={Link}
            to="/request-consultation"
            variant="contained"
            sx={{
              backgroundColor: "#E4B5A2",
              padding: "8px 14px",
              box: "10px",
              borderRadius: "6px", // XL border radius
              textTransform: "none", // Prevents text from being all uppercase
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Request Consultation
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DesktopNav;
