// TestServiceRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";

import FemaleSexualDysfunctionDetails from "./components/FemaleSexualDysfunctionDetails";
import MaleSexualDysfunctionDetails from "./components/MaleSexualDysfunctionDetails";
import OnlineSexualAddiction from "./components/OnlineSexualAddiction";
import AutismSupportForAdults from "./components/AutismSupportForAdults";
import AutismSupportForChildren from "./components/AutismSupportForChildren";
import LGBTQCounseling from "./components/LGBTQCounseling";
import TransgenderCounseling from "./components/TransgenderCounseling";
import StraightSpouseSupport from "./components/StraightSpouseSupport";
import MarriageCounseling from "./components/MarriageCounseling";
import SexTherapyForCouples from "./components/SexTherapyForCouples";
import LackOfDesire from "./components/LackOfDesire";
import NotFoundPage from "../common/NotFoundPage";

const TestServiceRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />

      <Route
        path="female-sexual-dysfunction"
        element={<FemaleSexualDysfunctionDetails />}
      />
      <Route
        path="male-sexual-dysfunction"
        element={<MaleSexualDysfunctionDetails />}
      />
      <Route
        path="online-sexual-addiction"
        element={<OnlineSexualAddiction />}
      />
      <Route
        path="autism-support-for-adults"
        element={<AutismSupportForAdults />}
      />
      <Route
        path="autism-support-for-children"
        element={<AutismSupportForChildren />}
      />
      <Route path="lgbtq-counseling" element={<LGBTQCounseling />} />
      <Route
        path="transgender-counseling"
        element={<TransgenderCounseling />}
      />
      <Route
        path="straight-spouse-support"
        element={<StraightSpouseSupport />}
      />
      <Route path="marriage-counseling" element={<MarriageCounseling />} />
      <Route
        path="sex-therapy-for-couples"
        element={<SexTherapyForCouples />}
      />
      <Route path="lack-of-desire" element={<LackOfDesire />} />
    </Routes>
  );
};

export default TestServiceRoutes;
