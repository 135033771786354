// theme.js
import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  typography: {
    h1: {
      fontWeight: 700,
      lineHeight: "57.6px",
      textAlign: "center",
      fontSize: "24px",
      marginBottom: "24px",
      [createTheme().breakpoints.up("md")]: {
        fontSize: "48px",
        marginBottom: "48px",
      },
    },
  },
});

export default theme;
