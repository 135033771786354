import React from "react";
import { SvgIcon } from "@mui/material";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

export const PrideFlagIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 48 24">
    <rect width="48" height="4" fill="#ff0000" />
    <rect y="4" width="48" height="4" fill="#ff7f00" />
    <rect y="8" width="48" height="4" fill="#ffff00" />
    <rect y="12" width="48" height="4" fill="#00ff00" />
    <rect y="16" width="48" height="4" fill="#0000ff" />
    <rect y="20" width="48" height="4" fill="#8b00ff" />
  </SvgIcon>
);

export const TransgenderFlagIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 48 24">
    <rect width="48" height="4.8" fill="#55cdfc" />
    <rect y="4.8" width="48" height="4.8" fill="#f7a8b8" />
    <rect y="9.6" width="48" height="4.8" fill="#ffffff" />
    <rect y="14.4" width="48" height="4.8" fill="#f7a8b8" />
    <rect y="19.2" width="48" height="4.8" fill="#55cdfc" />
  </SvgIcon>
);

export const AutismInfinityIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <defs>
      <linearGradient id="autismGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: "#ff0000", stopOpacity: 1 }} />
        <stop
          offset="16.67%"
          style={{ stopColor: "#ff7f00", stopOpacity: 1 }}
        />
        <stop
          offset="33.33%"
          style={{ stopColor: "#ffff00", stopOpacity: 1 }}
        />
        <stop offset="50%" style={{ stopColor: "#00ff00", stopOpacity: 1 }} />
        <stop
          offset="66.67%"
          style={{ stopColor: "#0000ff", stopOpacity: 1 }}
        />
        <stop
          offset="83.33%"
          style={{ stopColor: "#8b00ff", stopOpacity: 1 }}
        />
        <stop offset="100%" style={{ stopColor: "#ff0000", stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <AllInclusiveIcon style={{ fill: "url(#autismGradient)" }} />
  </SvgIcon>
);

export const ChildrenAutismInfinityIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <defs>
      <linearGradient id="childrenGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: "#ff69b4", stopOpacity: 1 }} />
        <stop offset="33%" style={{ stopColor: "#ff6347", stopOpacity: 1 }} />
        <stop offset="66%" style={{ stopColor: "#ffd700", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "#87ceeb", stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <AllInclusiveIcon style={{ fill: "url(#childrenGradient)" }} />
  </SvgIcon>
);
