import React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "./assets/logo_light.png";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: "#F8E2DD",
        color: "black",
        fontSize: "0.875rem",
        lineHeight: "1.43",
        letterSpacing: "0.01071em",
        width: "100%",
      }}
    >
      <Grid container sx={{ padding: { xs: "48px 16px", md: "88px 100px" } }}>
        <Grid item xs={12} sm={4} sx={{ mb: { xs: "48px", md: "32px" } }}>
          <Box
            sx={{ display: "flex", justifyContent: "flex-start", mb: "16px" }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ height: "40px", width: "auto" }}
            />
          </Box>
          <Typography
            variant="body2"
            gutterBottom
            align="left"
            sx={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "30px",
              textAlign: "left",
            }}
          >
            Ph.D., LMHC 14391
          </Typography>
          <Typography variant="body2" gutterBottom align="left">
            Licensed Mental Health Counselor
          </Typography>
          <Typography variant="body2" align="left" sx={{ mb: "24px" }}>
            Board Certified Clinical Sexologist
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Link
              href="https://www.facebook.com/profile.php?id=100064161485273"
              color="inherit"
            >
              <FacebookIcon />
            </Link>
            <Link href="#" color="inherit" sx={{ mx: 2 }}>
              <TwitterIcon />
            </Link>
            <Link href="#" color="inherit">
              <LinkedInIcon />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ mb: { xs: "48px", md: "32px" } }}>
          <Box
            sx={{ textAlign: "left", ml: { xs: 0, sm: "50px", md: "100px" } }}
          >
            <Typography
              variant="h6"
              gutterBottom
              align="left"
              sx={{
                fontFamily: "Roboto",
                fontSize: { xs: "16px", md: "16px" },
                fontWeight: 700,
                lineHeight: { xs: "24px", md: "24px" },
                textAlign: "left",
                mb: { xs: "16px", md: "32px" },
              }}
            >
              Quick Links
            </Typography>
            {["Home", "Services", "Rates", "Insurance", "Contact"].map(
              (text) => (
                <RouterLink
                  to={
                    text === "Home"
                      ? "/"
                      : `/${text.toLowerCase().replace(/ /g, "-")}`
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Link
                    color="#000"
                    display="block"
                    sx={{ marginBottom: "8px" }}
                    style={{ textDecoration: "none" }}
                  >
                    {text}
                  </Link>
                </RouterLink>
              )
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", sm: "center" },
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "grid" },
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            {" "}
            <Typography
              variant="h6"
              gutterBottom
              align="left"
              sx={{
                fontFamily: "Roboto",
                fontSize: { xs: "16px", md: "16px" },
                fontWeight: 700,
                lineHeight: { xs: "24px", md: "24px" },
                textAlign: "left",
                mb: { xs: "16px", md: "32px" },
              }}
            >
              Contact Details
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              align="left"
              sx={{ marginBottom: { xs: "16px" } }}
            >
              <Link
                href="tel:4077975469"
                sx={{ color: "black", textDecoration: "none" }}
              >
                407-797-5469
              </Link>
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              align="left"
              sx={{ marginBottom: { xs: "16px" } }}
            >
              <Link
                href="mailto:rothenbergcounseling@gmail.com"
                sx={{ color: "black", textDecoration: "none" }}
              >
                rothenbergcounseling@gmail.com
              </Link>
            </Typography>
            <Typography
              variant="body2"
              align="left"
              sx={{ marginBottom: { xs: "16px" } }}
            >
              <Link
                href="https://maps.app.goo.gl/cWZaSH3NGqFiBY6o9"
                target="_blank"
                rel="noreferrer"
                sx={{ color: "black", textDecoration: "none" }}
              >
                2700 Westhall Ln Ste 203,
                <br />
                Maitland, FL 32751
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
