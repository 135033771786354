// AccordionItem.js
import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function AccordionItem({ item, index, expanded, onChange }) {
  return (
    <Accordion
      expanded={expanded === `panel${index}`}
      onChange={onChange(`panel${index}`)}
      sx={{
        mb: 1,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}a-content`}
        id={`panel${index}a-header`}
        sx={{
          color: expanded === `panel${index}` ? "#E4B5A2" : "black",
          bgcolor: expanded === `panel${index}` ? "#FBF1EF" : "white",
        }}
      >
        <Typography
          sx={{ fontSize: { xs: "14px", sm: "16px", md: "20px" } }}
          align="left"
        >
          {item.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          margin: { xs: "16px 8px", sm: "20px", md: "16px 32px" },
        }}
      >
        <Typography
          align="left"
          sx={{ fontSize: { xs: "14px", sm: "14px", md: "16px" } }}
        >
          {item.answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
