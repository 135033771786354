import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";
import CustomHelmet from "../../common/CustomHelmet";

const AutismSupportForAdults = () => {
  return (
    <>
      <CustomHelmet
        title="Adult Autism Support Services - Dr. Anne Rothenberg"
        description="Dr. Anne Rothenberg provides specialized support services for adults with autism, focusing on social skills, employment support, and independent living. Learn strategies to navigate life's challenges effectively."
        keywords="adult autism support, autism counseling for adults, Dr. Anne Rothenberg autism services, autism social skills training, autism employment support, independent living for autistic adults"
        ogTitle="Specialized Autism Support for Adults with Dr. Anne Rothenberg"
        ogDescription="Explore targeted support and counseling services for adults with autism with Dr. Anne Rothenberg. Enhance your social skills, manage employment challenges, and improve independent living."
        ogUrl="https://drannerothenberg.com/services/autism-support-for-adults"
      />
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Autism Support for Adults
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you facing any of the following challenges?
          </Typography>
        </Box>
        <List
          dense
          sx={{
            listStyleType: "disc",
            pl: 4,
            color: "#333",
            textAlign: "left",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            Difficulty with social interactions and communication?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Challenges in understanding and expressing emotions?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Sensory sensitivities or aversions?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Struggles with maintaining routines and adapting to change?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Difficulties in finding and keeping employment?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Issues with independent living and daily life skills?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Anxiety, depression, or other mental health concerns?
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Building and maintaining relationships?
          </ListItem>
        </List>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1" gutterBottom sx={{ color: "#555" }}>
            Dr. Anne Rothenberg specializes in providing support and strategies
            to help adults with autism navigate the complexities of life,
            improve their quality of life, and achieve their personal goals.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "#4A90E2", mt: 2 }}
          >
            Areas of Focus in Counseling
          </Typography>
          <List
            dense
            sx={{
              listStyleType: "circle",
              pl: 4,
              color: "#333",
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              Social Skills Development
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Emotional Regulation
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Sensory Integration
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Routine Management
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Employment Support
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Independent Living Skills
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Mental Health Counseling
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Relationship Building
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Communication Strategies
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Self-Advocacy Skills
            </ListItem>
          </List>
        </Box>
      </Container>
    </>
  );
};

export default AutismSupportForAdults;
