import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";

const StraightSpouseSupport = () => {
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          textAlign: "left",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Straight Spouse Support
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ color: "#555" }}>
          Dr. Anne Rothenberg offers compassionate and understanding support for
          individuals whose partners have come out as gay, lesbian, or bisexual.
          This specialized counseling helps straight spouses navigate the
          complex emotions and challenges that arise from this life-changing
          revelation, fostering personal growth, healing, and finding a new path
          forward.
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ color: "#4A90E2", mt: 2 }}>
          Areas Covered in Straight Spouse Support Counseling
        </Typography>
        <List
          dense
          sx={{
            listStyleType: "disc",
            pl: 4,
            color: "#333",
            textAlign: "left",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>Emotional Trauma</ListItem>
          <ListItem sx={{ display: "list-item" }}>Grief and Loss</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Anger and Resentment
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Confusion and Uncertainty
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>Trust Issues</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Communication Challenges
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>Co-Parenting</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Divorce and Separation
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Identity and Self-Esteem
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>Social Stigma</ListItem>
          <ListItem sx={{ display: "list-item" }}>Rebuilding Life</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Finding New Relationships
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>Coping Strategies</ListItem>
          <ListItem sx={{ display: "list-item" }}>Support Networks</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Legal and Financial Issues
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Mental Health (Anxiety, Depression)
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Personal Growth and Empowerment
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default StraightSpouseSupport;
