import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";

function ContactPreferences({ formData, handleChange }) {
  return (
    <FormControl component="fieldset" margin="normal" fullWidth>
      <FormLabel component="legend">
        How would you prefer I get back to you?
      </FormLabel>
      <RadioGroup
        row
        name="contactMethod"
        value={formData.contactMethod}
        onChange={handleChange}
        sx={{ justifyContent: "center" }}
        required
      >
        <FormControlLabel value="email" control={<Radio />} label="Email" />
        <FormControlLabel value="text" control={<Radio />} label="Text" />
        <FormControlLabel value="call" control={<Radio />} label="Call" />
      </RadioGroup>
      {formData.contactMethod === "email" && (
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
      )}
      {(formData.contactMethod === "call" ||
        formData.contactMethod === "text") && (
        <TextField
          label="Phone number"
          name="phoneNumber"
          type="tel"
          value={formData.phoneNumber}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
      )}
    </FormControl>
  );
}

export default ContactPreferences;
