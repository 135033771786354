import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Typography, Box } from "@mui/material";

const formatBreadcrumb = (name) => {
  return name
    .split("-")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
};

const Breadcrumb = ({ backgroundImage }) => {
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((x) => x);

  if (pathname === "/") {
    return null;
  }

  return (
    <div
      style={{
        // backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "30px",
      }}
    >
      <Box
        sx={{
          padding: "20px 10px",
          display: "flex",
          alignItems: "center",
          marginLeft: { xs: "7%", md: "7%" },
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator=">"
          sx={{
            color: "#646464",
            fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
          }} // Responsive font size
        >
          <Link
            to="/"
            style={{
              color: "#646464",
              textDecoration: "none",
              fontSize: "inherit", // Use fontSize from parent Breadcrumbs
            }}
          >
            Home
          </Link>
          {pathnames.map((name, index) => {
            const formattedName = formatBreadcrumb(name);
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            return isLast ? (
              <Typography
                key={name}
                sx={{
                  color: "#646464",
                  fontWeight: "bold",
                  fontSize: "inherit", // Use fontSize from parent Breadcrumbs
                }}
              >
                {formattedName}
              </Typography>
            ) : (
              <Link
                key={name}
                to={routeTo}
                style={{
                  color: "#646464",
                  textDecoration: "none",
                  fontSize: "inherit", // Use fontSize from parent Breadcrumbs
                }}
              >
                {formattedName}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Box>
    </div>
  );
};

export default Breadcrumb;
