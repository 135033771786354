import React from "react";
import { Container, Box, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import TestSidebar from "./components/TestSidebar";
import TestServiceRoutes from "./TestServiceRoutes";
import Understand from "../common/Understand";
import useIsMobile from "../common/useIsMobile";
import CustomHelmet from "../common/CustomHelmet";
import ServicesLandingPage from "../Services/ServicesLandingPage";

const TestServices = () => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const isMainRoute = location.pathname === "/services";

  return (
    <>
      <CustomHelmet
        title="Counseling Services - Dr. Anne Rothenberg"
        description="Explore the range of counseling services offered by Dr. Anne Rothenberg, including individual and relationship therapy to enhance well-being and address various challenges."
        keywords="counseling services, individual therapy, relationship therapy, Dr. Anne Rothenberg, sexual dysfunction therapy, autism support, LGBTQ counseling, transgender counseling, marriage counseling, sex therapy, lack of desire"
        ogTitle="Counseling Services - Dr. Anne Rothenberg"
        ogDescription="Discover the counseling services offered by Dr. Anne Rothenberg, providing expert support for individuals and couples in various areas."
        ogUrl="https://drannerothenberg.com/services"
      />
      <Container sx={{ paddingRight: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            mt: 4,
          }}
        >
          <Grid container spacing={3}>
            {isMobile ? (
              <>
                {/* Mobile View for Main Route */}
                {isMainRoute ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      padding: "16px 16px",
                    }}
                  >
                    <ServicesLandingPage />
                  </Grid>
                ) : (
                  <>
                    {/* Mobile View for Non-Main Routes */}
                    <Grid item xs={12} md={9}>
                      <main>
                        <TestServiceRoutes />
                      </main>
                    </Grid>
                    <Understand />
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        paddingLeft: "0 !important",
                      }}
                    >
                      <TestSidebar />
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <>
                {/* Desktop View */}
                {isMainRoute ? (
                  <Grid item xs={12}>
                    <ServicesLandingPage />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={9}>
                      <main>
                        <TestServiceRoutes />
                      </main>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        backgroundColor: "#fcf7f1",
                        paddingLeft: "0 !important",
                      }}
                    >
                      <TestSidebar />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Box>
        {!isMobile && !isMainRoute && <Understand />}
      </Container>
    </>
  );
};

export default TestServices;
