import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";
import CustomHelmet from "../../common/CustomHelmet";

const OnlineSexualAddiction = () => {
  return (
    <>
      <CustomHelmet
        title="Online Sex Addiction Counseling - Dr. Anne Rothenberg, Orlando"
        description="Get confidential online counseling for sexual addiction with Dr. Anne Rothenberg, a licensed specialist in Orlando."
        keywords="sexual addiction counseling, pornography addiction help, sex therapy Orlando, cybersex treatment"
        ogTitle="Online Sex Addiction Counseling - Dr. Anne Rothenberg, Orlando"
        ogDescription="Professional and confidential online counseling for sexual addiction with Dr. Anne Rothenberg."
        ogUrl="https://drannerothenberg.com/services/online-sexual-addiction"
      />

      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Pornography Addiction and Sex Addiction Counseling
          </Typography>
          <List dense sx={{ listStyleType: "disc", pl: 4, mb: 2 }}>
            <ListItem sx={{ display: "list-item" }}>
              Do you (or does your partner/spouse) have a sexual addiction?
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Is your sexual behavior ruining your life?
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Can’t stop thinking about Internet porn?
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Have you tried to cut back or stop…but can’t?
            </ListItem>
          </List>
          <Typography variant="h6" gutterBottom>
            Are you experiencing:
          </Typography>
          <List dense sx={{ listStyleType: "disc", pl: 4, mb: 2 }}>
            <ListItem sx={{ display: "list-item" }}>Guilt?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Shame?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Anxiety?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Depression?</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Is your relationship in trouble?
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Do you experience excessive masturbation?
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Are you a survivor of sexual abuse or trauma?
            </ListItem>
          </List>
          <Typography variant="h6" gutterBottom>
            Issues With:
          </Typography>
          <List dense sx={{ listStyleType: "disc", pl: 4, mb: 2 }}>
            <ListItem sx={{ display: "list-item" }}>Intimacy?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Trust?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Honesty?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Communication?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Cybersex?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Chat Rooms?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Paying for Sex?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Anonymous Sex?</ListItem>
          </List>
          <Typography variant="h6" gutterBottom>
            Tired of making the same mistakes again and again?
          </Typography>
          <List dense sx={{ listStyleType: "disc", pl: 4, mb: 2 }}>
            <ListItem sx={{ display: "list-item" }}>Hurt?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Pain?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Confusion?</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Don’t know where to turn?
            </ListItem>
          </List>
          <Typography variant="h6" gutterBottom>
            Tired of living with:
          </Typography>
          <List dense sx={{ listStyleType: "disc", pl: 4, mb: 2 }}>
            <ListItem sx={{ display: "list-item" }}>Lack of intimacy?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Lies?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Cheating?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Rejection?</ListItem>
            <ListItem sx={{ display: "list-item" }}>Loneliness?</ListItem>
          </List>
          <Typography variant="h6" gutterBottom>
            Specializing in:
          </Typography>
          <List dense sx={{ listStyleType: "disc", pl: 4, mb: 2 }}>
            <ListItem sx={{ display: "list-item" }}>Sex Addiction</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Online Sexual Addiction
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Compulsive Sexual Behavior
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Impulsive Sexual Behavior
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Cybersex Addiction
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Pornography Addiction
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Compulsive Masturbation
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Survivors of Sexual Abuse and Trauma
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Male Survivors of Abuse and Trauma
            </ListItem>
          </List>
        </Box>
      </Container>
    </>
  );
};

export default OnlineSexualAddiction;
