import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";
import CustomHelmet from "../../common/CustomHelmet";

const TransgenderCounseling = () => {
  return (
    <>
      <CustomHelmet
        title="Transgender Counseling Services - Dr. Anne Rothenberg"
        description="Expert transgender counseling services by Dr. Anne Rothenberg. Support for gender identity, transitioning, hormone therapy, and mental health issues to help you live authentically."
        keywords="transgender counseling, Dr. Anne Rothenberg gender therapy, transitioning support, hormone therapy counseling, transgender mental health services"
        ogTitle="Comprehensive Transgender Counseling by Dr. Anne Rothenberg"
        ogDescription="Dr. Anne Rothenberg provides specialized counseling for transgender individuals, offering support for all aspects of transitioning and mental health."
        ogUrl="https://drannerothenberg.com/services/transgender-counseling"
      />
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Transgender Counseling
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ color: "#555" }}>
            Dr. Anne Rothenberg, an internationally recognized specialist in
            Human Sexuality, provides compassionate and expert counseling for
            transgender individuals. Dr. Rothenberg offers support in navigating
            the complexities of gender identity, transition, and overall mental
            health and well-being. Her goal is to help transgender individuals
            live authentically and achieve their personal and emotional goals.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "#4A90E2", mt: 2 }}
          >
            Areas Covered in Transgender Counseling
          </Typography>
          <List
            dense
            sx={{
              listStyleType: "disc",
              pl: 4,
              color: "#333",
              textAlign: "left",
            }}
          >
            <ListItem sx={{ display: "list-item" }}>Gender Dysphoria</ListItem>
            <ListItem sx={{ display: "list-item" }}>Coming Out</ListItem>
            <ListItem sx={{ display: "list-item" }}>Transitioning</ListItem>
            <ListItem sx={{ display: "list-item" }}>Hormone Therapy</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Surgery and Medical Care
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Social Transition</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Legal Name and Gender Marker Changes
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Family Support</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Relationship Issues
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Discrimination and Stigma
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Mental Health (Anxiety, Depression)
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Body Image</ListItem>
            <ListItem sx={{ display: "list-item" }}>Self-Esteem</ListItem>
            <ListItem sx={{ display: "list-item" }}>Sexual Health</ListItem>
            <ListItem sx={{ display: "list-item" }}>Substance Use</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Employment and Workplace Issues
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Community Resources
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Coping Strategies</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Self-Advocacy Skills
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Building Resilience
            </ListItem>
          </List>
        </Box>
      </Container>
    </>
  );
};

export default TransgenderCounseling;
