import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";

const AutismSupportForChildren = () => {
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          textAlign: "left",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Autism Support for Children
        </Typography>
        <Typography variant="body1" gutterBottom>
          Are you facing any of the following challenges with your child?
        </Typography>
      </Box>
      <List
        dense
        sx={{
          listStyleType: "disc",
          pl: 4,
          color: "#333",
          textAlign: "left",
        }}
      >
        <ListItem sx={{ display: "list-item" }}>
          Difficulty with social interactions and communication?
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Challenges in understanding and expressing emotions?
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Sensory sensitivities or aversions?
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Struggles with maintaining routines and adapting to change?
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Difficulty with learning and schoolwork?
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Issues with independent living skills?
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Anxiety, depression, or other mental health concerns?
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Building and maintaining friendships?
        </ListItem>
      </List>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          padding: "20px",
          borderRadius: "8px",
          mb: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom sx={{ color: "#555" }}>
          Dr. Anne Rothenberg specializes in providing support and strategies to
          help children with autism navigate their developmental stages, improve
          their quality of life, and achieve their personal goals.
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ color: "#4A90E2", mt: 2 }}>
          Areas of Focus in Counseling
        </Typography>
        <List
          dense
          sx={{
            listStyleType: "circle",
            pl: 4,
            color: "#333",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            Social Skills Development
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Emotional Regulation
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>Sensory Integration</ListItem>
          <ListItem sx={{ display: "list-item" }}>Routine Management</ListItem>
          <ListItem sx={{ display: "list-item" }}>Learning Support</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Independent Living Skills
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Mental Health Counseling
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>Friendship Building</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Communication Strategies
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Self-Advocacy Skills
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default AutismSupportForChildren;
