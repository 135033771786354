import React, { useState } from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Backdrop,
} from "@mui/material";
import ContactPreferences from "./ContactPreferences";
import BasicInfoFields from "./BasicInfoFields";

function ConsultationForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactMethod: "",
    email: "",
    phoneNumber: "",
    preference: "",
    reason: "",
  });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.firstName || !formData.lastName || !formData.contactMethod) {
      alert("Please make sure all required fields are filled out.");
      return;
    }

    setLoading(true);
    fetch(
      "https://cmam0ede8e.execute-api.us-east-2.amazonaws.com/Test/drAnneConsultation",
      {
        method: "POST",
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        setSubmitted(true);
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  if (submitted) {
    return (
      <Container
        maxWidth="md"
        style={{ padding: "40px 16px", textAlign: "center", color: "#6f4e37" }}
      >
        <Typography variant="h5" style={{ marginTop: 20, fontWeight: "bold" }}>
          Thank you for taking the first step.
        </Typography>
        <Typography variant="h6" style={{ marginTop: 20 }}>
          Dr. Anne Rothenberg will be in touch within 48 hours. We're excited to
          help you on your journey.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" style={{ padding: "40px 16px" }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ m: 2 }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h1">Request a Consultation</Typography>
        <BasicInfoFields formData={formData} handleChange={handleChange} />
        <ContactPreferences formData={formData} handleChange={handleChange} />
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2, backgroundColor: "#8a5a44" }}
        >
          Send Request
        </Button>
      </Box>
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Dr. Anne Rothenberg will be in touch within 48 hours!"
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#6f4e37" } }}
      />
    </Container>
  );
}

export default ConsultationForm;
