import React from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Link,
  Divider,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Understand from "../common/Understand";
import CustomHelmet from "../common/CustomHelmet";

function PricingAndInsurance() {
  const navigate = useNavigate();
  const rates = [
    {
      id: 1,
      title: "In Office / Virtual",
      price: "$185",
      duration: "60 Minute Session",
      description:
        "Choose between traditional in-office therapy or the convenience of virtual sessions from the comfort of your home via platforms like FaceTime, Zoom, and others.",
    },
    {
      id: 2,
      title: "After Hours / Intensive / Travel Sessions",
      price: "$300",
      duration: "60 Minute Session",
      description:
        "Available for urgent needs, intensive sessions, or sessions requiring travel. Ideal for those requiring more flexible options.",
    },
  ];

  return (
    <>
      <CustomHelmet
        title="Self-Pay Therapy Rates - Flexible Options Available"
        description="Explore transparent self-pay rates for therapy sessions with Dr. Anne Rothenberg. Choose from in-office or virtual therapy sessions to suit your needs. Flexible options including after-hours and travel sessions available."
        keywords="self-pay therapy rates, virtual therapy costs, in-office therapy pricing, after-hours therapy sessions, travel therapy rates, Dr. Anne Rothenberg therapy options"
        ogTitle="Explore Self-Pay Therapy Rates with Dr. Anne Rothenberg"
        ogDescription="Discover flexible self-pay options for therapy with Dr. Anne Rothenberg, including virtual and in-office sessions. Affordable rates for personalized care."
        ogUrl="https://drannerothenberg.com/rates"
      />
      <Container maxWidth="lg" style={{ padding: "40px 16px" }}>
        <Typography
          variant="h1"
          sx={{
            mb: { xs: "40px", sm: "72px" },
          }}
        >
          Self-Pay Options
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {rates.map((rate) => (
            <Grid item xs={12} md={6} key={rate.id}>
              <Card
                raised
                elevation={0}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginBottom: 2,
                  minHeight: 235,
                  borderRadius: { xs: "12px", md: "20px" },
                  border: "1px solid #E4B5A2",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{ fontWeight: "bold" }}
                    align="center"
                    sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }}
                  >
                    {rate.price}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="center"
                  >
                    {rate.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {rate.duration}
                  </Typography>
                  <Divider sx={{ marginY: 2 }} />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginTop: "10px" }}
                    align="center"
                    sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}
                  >
                    {rate.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          }}
        >
          <Box
            sx={{
              padding: "16px 32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              borderRadius: "8px",
              border: "1px solid #E9D502",
              backgroundColor: "#FDFBE6",
              marginTop: "20px",
              marginBottom: "20px",
              width: { xs: "100%", md: "80%" },
            }}
          >
            <Typography align="center" sx={{ opacity: 1 }}>
              Plan to use insurance?{" "}
              <Link
                component="button"
                onClick={() => navigate("/insurance")}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#1976d2",
                }}
              >
                View my Insurance Options
              </Link>
            </Typography>
          </Box>
        </Container>
        <Understand />
      </Container>
    </>
  );
}

export default PricingAndInsurance;
