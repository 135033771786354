import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";

const LackOfDesire = () => {
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          textAlign: "left",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Lack of Desire
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ color: "#555" }}>
          Dr. Anne Rothenberg provides expert counseling for individuals and
          couples experiencing a lack of sexual desire. Understanding the
          complexities behind low libido, Dr. Rothenberg offers a safe and
          supportive environment to explore the underlying causes and develop
          strategies to rekindle sexual interest and intimacy.
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ color: "#4A90E2", mt: 2 }}>
          Common Issues Addressed in Counseling for Lack of Desire
        </Typography>
        <List
          dense
          sx={{
            listStyleType: "disc",
            pl: 4,
            color: "#333",
            textAlign: "left",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>Stress and Fatigue</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Relationship Conflicts
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Communication Issues
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>Hormonal Changes</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Mental Health Concerns (Anxiety, Depression)
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>Body Image Issues</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Past Trauma or Abuse
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>Mismatched Libidos</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Lack of Sexual Confidence
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Physical Health Issues
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Medication Side Effects
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>Lack of Intimacy</ListItem>
          <ListItem sx={{ display: "list-item" }}>Sexual Boredom</ListItem>
          <ListItem sx={{ display: "list-item" }}>Performance Anxiety</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Exploring Sexual Preferences
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Building Emotional Connection
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Improving Sexual Communication
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Rediscovering Sexual Pleasure
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default LackOfDesire;
