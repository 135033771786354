import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import logo from "../assets/logo_light.png";

const MobileNav = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const linkStyle = {
    textDecoration: "none", // No underline
    color: "inherit", // Use text color
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{ padding: "10px 20px" }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Link to="/" style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="Logo" style={{ height: "40px" }} />
        </Link>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ marginLeft: "auto" }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {[
                "Home",
                "Services",
                "Rates",
                "Insurance",
                "Contact",
                "Request Consultation",
              ].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  component={Link}
                  to={
                    text === "Home"
                      ? "/"
                      : `/${text.toLowerCase().replace(/ /g, "-")}`
                  }
                  style={linkStyle}
                >
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default MobileNav;
