import React from "react";
import { Typography, List, ListItem, Container, Box } from "@mui/material";
import CustomHelmet from "../../common/CustomHelmet";

const LGBTQCounseling = () => {
  return (
    <>
      <CustomHelmet
        title="LGBTQ Counseling Services - Dr. Anne Rothenberg"
        description="Dr. Anne Rothenberg offers specialized LGBTQ counseling services for individuals and couples, addressing a range of issues including relationship challenges, coming out, and mental health concerns."
        keywords="LGBTQ counseling, gay lesbian therapy, LGBTQ mental health, relationship counseling, Dr. Anne Rothenberg LGBTQ services, coming out support, gay lesbian relationship issues"
        ogTitle="Comprehensive LGBTQ Counseling Services with Dr. Anne Rothenberg"
        ogDescription="Explore supportive and affirmative LGBTQ counseling services with Dr. Anne Rothenberg, addressing unique challenges faced by gay and lesbian individuals and couples."
        ogUrl="https://drannerothenberg.com/services/lgbtq-counseling"
      />
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            textAlign: "left",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            LGBTQ Counseling for Individuals and Couples
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ color: "#555" }}>
            As an internationally recognized specialist in Human Sexuality, Dr.
            Anne Rothenberg provides specialized, supportive, and affirmative
            counseling and relationship therapy for gay and lesbian individuals
            and couples. Dr. Anne Rothenberg is a Board Certified Clinical
            Sexologist who works extensively with LGBT individuals and couples.
            Dr. Anne Rothenberg has helped countless gay and lesbian individuals
            and couples to live happier lives!
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "#4A90E2", mt: 2 }}
          >
            Areas Covered In Counseling with Gay and Lesbian Individuals and
            Couples
          </Typography>
          <List
            dense
            sx={{
              listStyleType: "disc",
              pl: 4,
              color: "#333",
              textAlign: "left",
            }}
          >
            <ListItem sx={{ display: "list-item" }}>Depression</ListItem>
            <ListItem sx={{ display: "list-item" }}>Anxiety</ListItem>
            <ListItem sx={{ display: "list-item" }}>Loneliness</ListItem>
            <ListItem sx={{ display: "list-item" }}>Honesty</ListItem>
            <ListItem sx={{ display: "list-item" }}>Trust</ListItem>
            <ListItem sx={{ display: "list-item" }}>Communication</ListItem>
            <ListItem sx={{ display: "list-item" }}>Loss of Desire</ListItem>
            <ListItem sx={{ display: "list-item" }}>Loss of Arousal</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Sexual Incompatibility
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Sexual Addiction</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Sexually Compulsive Behavior
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Sexually Impulsive Behavior
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Unusual Sexual Behaviors
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Relationship Issues
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Additional Partners
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Anonymous Sex</ListItem>
            <ListItem sx={{ display: "list-item" }}>Lying</ListItem>
            <ListItem sx={{ display: "list-item" }}>Cheating</ListItem>
            <ListItem sx={{ display: "list-item" }}>Infidelity</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Emotional Connection
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Coming Out Issues</ListItem>
            <ListItem sx={{ display: "list-item" }}>Family Issues</ListItem>
            <ListItem sx={{ display: "list-item" }}>Disapproval</ListItem>
            <ListItem sx={{ display: "list-item" }}>Co-Dependency</ListItem>
            <ListItem sx={{ display: "list-item" }}>Desire to Please</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Gay/Lesbian Children
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Gay/Lesbian Spouses
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Religious Issues</ListItem>
            <ListItem sx={{ display: "list-item" }}>Spirituality</ListItem>
            <ListItem sx={{ display: "list-item" }}>Excess Drinking</ListItem>
            <ListItem sx={{ display: "list-item" }}>Excess Drugging</ListItem>
            <ListItem sx={{ display: "list-item" }}>Body Image</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Sexuality and Illness
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>HIV/AIDS</ListItem>
            <ListItem sx={{ display: "list-item" }}>Other Illnesses</ListItem>
            <ListItem sx={{ display: "list-item" }}>Loss and Grief</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Gay/Lesbian Widowers
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Middle Age Issues</ListItem>
            <ListItem sx={{ display: "list-item" }}>Dating Issues</ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Survivors of Sexual Abuse
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Unhappy Childhoods
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Childhood Trauma</ListItem>
          </List>
        </Box>
      </Container>
    </>
  );
};

export default LGBTQCounseling;
