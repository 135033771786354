import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDatadogRum } from "./BotAndRum";

import "./App.css";
import Home from "./components/Home/Home";
import Footer from "./components/Footer";
import Rates from "./components/Rates/Rates";
import Contact from "./components/Contact/contact";
import Insurance from "./components/Insurance/Insurance";
import Breadcrumb from "./components/Breadcrumb";
import TestServices from "./components/TestServices/TestServices";
import ScrollToTop from "./components/ScrollToTop";
import ConsultationForm from "./components/Consultation/ConsultationForm";
import BreadCrumbImage from "./components/assets/breadcrumbimagelight.jpg";
import UtilityNav from "./components/common/UtilityNav";
import NotFoundPage from "./components/common/NotFoundPage";
import NewNav from "./components/common/Nav";
import theme from "./theme";
import { ThemeProvider, CssBaseline } from "@mui/material";

// import MarriageCounseling from "./components/Services/MarriageCounseling";
// import ServicesLandingPage from "./components/Services/ServicesLandingPage";

// const services = [
//   { path: "marriage-counseling", Component: MarriageCounseling },
// ];

function App() {
  useDatadogRum();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        className="App"
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Router>
          <ScrollToTop />
          <UtilityNav />
          <NewNav />
          <Breadcrumb backgroundImage={BreadCrumbImage} />
          <div style={{ flex: 1 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/rates" element={<Rates />} />
              <Route path="/insurance" element={<Insurance />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/services/*" element={<TestServices />} />
              {/* <Route exact path="/services2" element={<ServicesLandingPage />} />
            {services.map(({ path, Component }) => (
              <Route
                key={path}
                path={`/services2/${path}`}
                element={<Component />} // This now points to the correct component
              />
            ))} */}
              <Route
                path="/request-consultation"
                element={<ConsultationForm />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
