import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

const botPatterns = [
  "googlebot",
  "bingbot",
  "slurp",
  "duckduckbot",
  "baiduspider",
  "yandexbot",
  "sogou",
  "exabot",
  "facebot",
  "ia_archiver",
  "crawler",
  "spider",
  "bot[/:;s]",
  "A6-Indexer",
  "ADmantX",
  "headlesschrome",
];

const botRegex = new RegExp(botPatterns.join("|"), "i");

const likelyBot = (userAgent, navigator) => {
  return botRegex.test(userAgent) || !navigator.cookieEnabled;
};

export function useDatadogRum() {
  const userAgent = navigator.userAgent;
  const isBot = likelyBot(userAgent, navigator);

  useEffect(() => {
    if (
      !isBot &&
      process.env.NODE_ENV === "production" &&
      !window.datadogRumInitialized
    ) {
      datadogRum.init({
        applicationId: "1348d448-872a-4dc7-b1c5-becd78e9620d",
        clientToken: "pubf22b4d3d903daac80a8a243b262818f7",
        site: "us5.datadoghq.com",
        service: "anne-rothenberg",
        env: "production",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
      });
      window.datadogRumInitialized = true;
    }
  }, [isBot]);
}

export default useDatadogRum;
