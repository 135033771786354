import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useAccordion } from "./useAccordion";
import { AccordionItem } from "./AccordionItem";

const faqItems = [
  {
    question: "What types of issues do sex therapists address?",
    answer:
      "Sex therapists are trained professionals who help with a wide array of sexual concerns, including sexual dysfunction, sexual pleasure, sexual orientation, gender identity, and sexual health across various life stages. They may work in individual, couple, or group therapy settings.",
  },
  {
    question: "How do I get started?",
    answer:
      "Getting started is easy! Simply contact Dr. Rothenberg directly to schedule a free initial 15 minute consultation. This session will help Dr. Rothenberg understand your needs and discuss how she can assist you.",
  },
  {
    question: "Does Dr. Rothenberg offer virtual counseling sessions?",
    answer:
      "Yes, Dr. Rothenberg provides virtual counseling options to accommodate clients who prefer online sessions or cannot visit the office in person.",
  },
  {
    question: "What insurance plans does Dr. Rothenberg accept?",
    answer:
      "Dr. Rothenberg is in-network with Aetna, Cigna, Oscar, Oxford, and United Health Care insurance plans.",
  },
  {
    question: "Are virtual sessions covered by insurance?",
    answer:
      "Typically, insurance plans cover virtual sessions in the same way as in-person sessions. However, it is recommended to verify coverage with your insurance provider.",
  },
];

function FAQPage() {
  const { expanded, handleChange } = useAccordion();

  return (
    <Box sx={{ padding: { xs: "32px 8px", md: "100px 64px" } }}>
      <Grid container sx={{ padding: { xs: "8px" } }}>
        <Grid item xs={12} md={4} sx={{ mb: "32px" }}>
          <Typography variant="h5">Frequently Asked Questions</Typography>
          <Typography>Got Questions? We've Got Answers!</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          {faqItems.map((item, index) => (
            <AccordionItem
              key={index}
              item={item}
              index={index}
              expanded={expanded}
              onChange={handleChange}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default FAQPage;
